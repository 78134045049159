export enum ContactCaseSubject {
    ORDER = 'Order',
    TECHNICAL = 'Technical',
    RETURN = 'Return',
    PRODUCT_INFO = 'Product Information',
    WORKSTATUS_ORDER = 'Workstatus order',
    FEEDBACK = 'Feedback',
    OTHER = 'Other',
}

export const ContactCaseQueue: Record<string, string> = {
    OTHER: 'Customer Service',
    [ContactCaseSubject.TECHNICAL]: 'Technische Dienst',
};

export enum ContactCaseType {
    DEALER_CS = 'Dealer CS',
}

export const SubjectOptions = [
    { label: 'contact.subject.order', value: ContactCaseSubject.ORDER },
    { label: 'contact.subject.technical', value: ContactCaseSubject.TECHNICAL },
    { label: 'contact.subject.return', value: ContactCaseSubject.RETURN },
    { label: 'contact.subject.workStatusOrder', value: ContactCaseSubject.WORKSTATUS_ORDER },
    { label: 'contact.subject.productInformation', value: ContactCaseSubject.PRODUCT_INFO },
    { label: 'contact.subject.feedback', value: ContactCaseSubject.FEEDBACK },
    { label: 'contact.subject.other', value: ContactCaseSubject.OTHER },
];

export const ContactCaseReasonL2: Record<string, string> = {
    [ContactCaseSubject.ORDER]: 'Order',
    [ContactCaseSubject.RETURN]: 'Return',
    [ContactCaseSubject.PRODUCT_INFO]: 'Product',
    [ContactCaseSubject.FEEDBACK]: 'Other',
    [ContactCaseSubject.OTHER]: 'Other',
};

export enum ContactCaseReasonL3 {
    AVAILABILITY = 'Product Availability',
    INFO = 'Product Information',
    PRICE = 'Product Price',
}

export const ContactCaseReasonL3Options = [
    { label: 'contact.caseReasonL3.availability', value: ContactCaseReasonL3.AVAILABILITY },
    { label: 'contact.caseReasonL3.info', value: ContactCaseReasonL3.INFO },
    { label: 'contact.caseReasonL3.price', value: ContactCaseReasonL3.PRICE },
];

export enum ContactCaseReasonL4 {
    BATTERY = 'Battery',
    SPARE_PART = 'Spare part',
    ECHO = 'Echo',
    MIRA = 'Mira',
    PERFORMANCE = 'Performance',
    INTER = 'Inter',
    PREMIUM_Q = 'Premium Q',
    ATLAS = 'Atlas',
    LEGACY = 'Legacy',
}

export const ContactCaseReasonL4Options = [
    { label: 'contact.caseReasonL4.battery', value: ContactCaseReasonL4.BATTERY },
    { label: 'contact.caseReasonL4.sparePart', value: ContactCaseReasonL4.SPARE_PART },
    { label: 'contact.caseReasonL4.echo', value: ContactCaseReasonL4.ECHO },
    { label: 'contact.caseReasonL4.mira', value: ContactCaseReasonL4.MIRA },
    { label: 'contact.caseReasonL4.performance', value: ContactCaseReasonL4.PERFORMANCE },
    { label: 'contact.caseReasonL4.inter', value: ContactCaseReasonL4.INTER },
    { label: 'contact.caseReasonL4.premiumQ', value: ContactCaseReasonL4.PREMIUM_Q },
    { label: 'contact.caseReasonL4.atlas', value: ContactCaseReasonL4.ATLAS },
    { label: 'contact.caseReasonL4.legacy', value: ContactCaseReasonL4.LEGACY },
];

export const RequiredField: Record<string, Record<string, string>> = {
    [ContactCaseSubject.ORDER]: {
        name: 'orderNumber',
        intl: 'contact.orderNumberLabel',
        placeholderIntl: 'contact.orderNumber.placeholder',
    },
    [ContactCaseSubject.TECHNICAL]: {
        name: 'frameNumber',
        intl: 'contact.frameNumberLabel',
        placeholderIntl: 'contact.frameNumber.placeholder',
    },
    [ContactCaseSubject.RETURN]: {
        name: 'returnCaseNumber',
        intl: 'contact.returnNumberLabel',
    },
    [ContactCaseSubject.WORKSTATUS_ORDER]: {
        name: 'workOrderNumber',
        intl: 'contact.workorderNumberLabel',
    },
    [ContactCaseReasonL4.BATTERY]: {
        name: 'batteryModel',
        intl: 'contact.batteryModelLabel',
        placeholderIntl: 'contact.batteryModel.placeholder',
    },
};

export interface IValues {
    subject: string;
    reasonL3?: string;
    reasonL4?: string;
    orderNumber?: string;
    frameNumber?: string;
    returnCaseNumber?: string;
    workOrderNumber?: string;
    batteryModel?: string;
    description: string;
    attachments?: File[];
}
